import { createApp } from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue-next'
import router from './router'
import store from './store'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'

createApp(App)
	.use(router)
	.use(BootstrapVue)
  .use(store)
	.mount("#app");
