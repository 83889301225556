<template>
	<nav class="navbar navbar-expand navbar-dark bg-dark">
		<div class="container-fluid">
			<router-link to="/" class="navbar-brand">
				<fa6-solid-gears/> Gear
			</router-link>
			<button
				class="navbar-toggler"
				type="button"
				data-bs-toggle="collapse"
				data-bs-target="#navbarSupportedContent"
				aria-controls="navbarSupportedContent"
				aria-expanded="false"
				aria-label="Toggle navigation">
				<span class="navbar-toggler-icon"></span>
			</button>
			<div class="collapse navbar-collapse" id="navbarSupportedContent">

				<!-- common links -->
				<ul class="navbar-nav me-auto mb-2 mb-lg-0">
					<li class="nav-item">
						<router-link v-if="currentUser" to="/computers" class="nav-link icon-link">
							<clarity-computer-line/> Ordinateurs
						</router-link>
					</li>
					<li class="nav-item">
						<router-link v-if="currentUser" to="/servers" class="nav-link icon-link">
							<mdi-server-outline/> Serveurs
						</router-link>
					</li>
					<li class="nav-item">
						<router-link v-if="currentUser" to="/owners" class="nav-link icon-link">
							<fa6-regular-address-card/> Bénéficiaires
						</router-link>
					</li>
					<BNavItemDropdown v-if="currentUser" dark>
						<template #button-content>
							<div class="icon-link">
								<mdi-tools/> Outils
							</div>
						</template>
						<BDropdownItem>
							<router-link v-if="currentUser" to="/computers/add" class="nav-link icon-link">
								<mdi-plus/> Ajouter un ordinateur
							</router-link>
						</BDropdownItem>
						<BDropdownItem>
							<router-link v-if="currentUser" to="/servers/add" class="nav-link icon-link">
								<mdi-server-plus/> Ajouter un serveur
							</router-link>
						</BDropdownItem>
						<BDropdownItem>
							<router-link v-if="currentUser" to="/users" class="nav-link icon-link">
								<mdi-account-multiple/> Utilisateur·rices
							</router-link>
						</BDropdownItem>
						<BDropdownItem v-if="currentUser">
							<a class="nav-link icon-link" @click.prevent="download">
								<mdi-download/> Télécharger la base
							</a>
						</BDropdownItem>
					</BNavItemDropdown>
				</ul>

				<!-- if not logged in -->
				<ul v-if="!currentUser" class="navbar-nav ml-auto">
					<li class="nav-item">
						<router-link to="/register" class="nav-link icon-link">
							<mdi-account-plus/> S’inscrire
						</router-link>
					</li>
					<li class="nav-item">
						<router-link to="/login" class="nav-link icon-link">
							<teenyicons-signin-outline/> Connexion
						</router-link>
					</li>
				</ul>

				<!-- if logged in -->
				<ul v-if="currentUser" class="navbar-nav ml-auto">
					<li class="nav-item">
						<router-link to="/profile" class="nav-link icon-link">
							<mdi-account/>
							{{ currentUser.userName }}
						</router-link>
					</li>
					<li class="nav-item">
						<a class="nav-link icon-link" @click.prevent="logOut">
							<teenyicons-logout-outline/> Déconnexion
						</a>
					</li>
				</ul>

			</div>
		</div>
	</nav>

	<router-view />

</template>

<script>

import ClarityComputerLine from '~icons/clarity/computer-line'
import Fa6RegularAddressCard from '~icons/fa6-regular/address-card'
import Fa6SolidGears from '~icons/fa6-solid/gears'
import MdiAccount from '~icons/mdi/account'
import MdiAccountMultiple from '~icons/mdi/account-multiple'
import MdiAccountPlus from '~icons/mdi/account-plus'
import MdiDownload from '~icons/mdi/download'
import MdiPlus from '~icons/mdi/plus'
import MdiServerPlus from '~icons/mdi/server-plus'
import MdiServerOutline from '~icons/mdi/server-outline'
import MdiTools from '~icons/mdi/tools'
import TeenyiconsLogoutOutline from '~icons/teenyicons/logout-outline'
import TeenyiconsSigninOutline from '~icons/teenyicons/signin-outline'

import ComputerService from './services/computer.service'
import ServerService from './services/server.service'

export default {
	data() {
		return {
			message: ""
		}
	},
	computed: {
		currentUser() {
			return this.$store.state.auth.user
		}
	},
	components: {
		ClarityComputerLine,
		Fa6RegularAddressCard,
		Fa6SolidGears,
		MdiAccount,
		MdiAccountMultiple,
		MdiAccountPlus,
		MdiDownload,
		MdiPlus,
		MdiServerPlus,
		MdiServerOutline,
		MdiTools,
		TeenyiconsLogoutOutline,
		TeenyiconsSigninOutline
	},
	methods: {
		download() {
			if (this.currentUser) {
				ComputerService.download()
					.then(response => {
						const link = document.createElement('a')
						link.href = window.URL.createObjectURL(
							new Blob([ JSON.stringify(response.data) ])
						)
						link.setAttribute('download', 'computers.json')
						document.body.appendChild(link)
						link.click()
					})
					.catch( error => {
						this.message =
							(error.response &&
								error.response.data &&
								error.response.data.message) ||
							error.message ||
							error.toString()
				})

				ServerService.download()
					.then(response => {
						const link = document.createElement('a')
						link.href = window.URL.createObjectURL(
							new Blob([JSON.stringify(response.data)])
						)
						link.setAttribute('download', 'servers.json')
						document.body.appendChild(link)
						link.click()
					})
					.catch(error => {
						this.message =
							(error.response &&
								error.response.data &&
								error.response.data.message) ||
							error.message ||
							error.toString()
					})

			} else {
				this.$router.push('/login')
			}
		},
		logOut() {
			this.$store.dispatch('auth/logout')
			this.$router.push('/login')
		}
	}
}

</script>
