import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_API_URL + '/servers/';

class ServerService {

  addServer(server) {
    return axios.post(API_URL + 'add', {
      asset: server.asset,
      brand: server.brand,
      budget: server.budget,
      comments: server.comments,
      designation: server.designation,
      encryption: server.encryption,
      inventory: server.inventory,
      reference: server.reference,
      origin: server.origin,
      OS: server.OS,
      owner: server.owner,
      purchased: server.purchased,
      room: server.room,
      serial: server.serial,
      type: server.type,
      warranty: server.warranty
    }, { headers: authHeader() });
  }

  deleteServer(id) {
    return axios.delete(API_URL + id, { headers: authHeader() });
  }

  download() {
    return axios.get(API_URL, { headers: authHeader() });
  }

  getAllServers() {
    return axios.get(API_URL, { headers: authHeader() });
  }

  getOneServer(id) {
    return axios.get(API_URL + id, { headers: authHeader() });
  }

  updateServer(id, server) {
    return axios.put(API_URL + id, {
      asset: server.asset,
      brand: server.brand,
      budget: server.budget,
      comments: server.comments,
      designation: server.designation,
      encryption: server.encryption,
      inventory: server.inventory,
      reference: server.reference,
      origin: server.origin,
      OS: server.OS,
      owner: server.owner,
      purchased: server.purchased,
      room: server.room,
      serial: server.serial,
      type: server.type,
      warranty: server.warranty
    }, { headers: authHeader() });
  }

}

export default new ServerService();
