import { createRouter, createWebHistory } from 'vue-router'
import { Buffer } from 'buffer'
import store from '../store'

// lazy-loading
const Computers = () => import('../components/Computers.vue')
const Profile = () => import('../components/Profile.vue')
const ComputerAdd = () => import('../components/ComputerAdd.vue')
const ComputerDetails = () => import('../components/ComputerDetails.vue')
const ComputerDelete = () => import('../components/ComputerDelete.vue')
const ComputerUpdate = () => import('../components/ComputerUpdate.vue')
const Login = () => import('../components/Login.vue')
const Owners = () => import('../components/Owners.vue')
const OwnerDevices = () => import('../components/OwnerDevices.vue')
const Register = () => import('../components/Register.vue')
const Servers = () => import('../components/Servers.vue')
const ServerAdd = () => import('../components/ServerAdd.vue')
const ServerDetails = () => import('../components/ServerDetails.vue')
const ServerDelete = () => import('../components/ServerDelete.vue')
const ServerUpdate = () => import('../components/ServerUpdate.vue')
const Users = () => import('../components/Users.vue')

const routes = [
	{
		path: '/',
		name: 'home',
		redirect: '/profile'
	},
	{
		path: "/computers",
		name: "computers",
		component: Computers,
	},
	{
		path: '/login',
		name: 'login',
		component: Login
	},
	{
		path: "/profile",
		name: "profile",
		component: Profile,
	},
	{
		path: "/register",
		name: 'register',
		component: Register,
	},
	{
		path: "/users",
		name: "users",
		component: Users,
	},
	{
		path: "/owners",
		name: "owners",
		component: Owners
	},
	{
		path: "/owner/:owner",
		name: "owner-devices",
		component: OwnerDevices
	},
	{
		path: "/computers/add",
		name: "computer-add",
		component: ComputerAdd,
	},
	{
		path: "/computer/:id",
		name: "computer-details",
		component: ComputerDetails
	},
	{
		path: "/computer/update/:id",
		name: "computer-update",
		component: ComputerUpdate
	},
	{
		path: "/computer/delete/:id",
		name: "computer-delete",
		component: ComputerDelete
	},
	{
		path: "/servers",
		name: "servers",
		component: Servers,
	},
	{
		path: "/servers/add",
		name: "server-add",
		component: ServerAdd,
	},
	{
		path: "/server/:id",
		name: "server-details",
		component: ServerDetails
	},
	{
		path: "/server/delete/:id",
		name: "server-delete",
		component: ServerDelete
	},
	{
		path: "/server/update/:id",
		name: "server-update",
		component: ServerUpdate
	}
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

function parseJwt (token) {
	const base64Url = token.split('.')[1]
	const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
	const jsonPayload = decodeURIComponent(
		Buffer
			.from(base64, "base64")
			.toString("ascii")
			.split('')
			.map( c => {
				return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
			})
			.join('')
		)

	return JSON.parse(jsonPayload)
}

router.beforeEach((to, from, next) => {

	// list of public pages
	const publicPages = ['login', 'register']

	// boolean to know if authentication is required or not
	const authRequired = !publicPages.includes(to.name)

	// check if user is logged in
	const loggedIn = localStorage.getItem('user')

	// if logged in, check validity of the token
	if (loggedIn) {
		const jwtPayload = parseJwt(localStorage.user)
		if (jwtPayload.exp < Date.now()/1000) {
			// token expired
			store.dispatch('auth/logout')
			if (authRequired)
				next('/login')
		}
	}

	// trying to access a restricted page + not logged in?
	// redirect to login page
	if (authRequired && !loggedIn) {
		next('/login')
	} else {
		next()
	}

})

export default router
