import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_API_URL + '/computers/';

class ComputerService {

  addComputer(computer) {
    return axios.post(API_URL + 'add', {
      brand: computer.brand,
      budget: computer.budget,
      comments: computer.comments,
      designation: computer.designation,
      encryption: computer.encryption,
      reference: computer.reference,
      origin: computer.origin,
      OS: computer.OS,
      owner: computer.owner,
      purchased: computer.purchased,
      room: computer.room,
      serial: computer.serial,
      type: computer.type,
      warranty: computer.warranty
    }, { headers: authHeader() });
  }

  deleteComputer(id) {
    return axios.delete(API_URL + id, { headers: authHeader() });
  }

  download() {
    return axios.get(API_URL, { headers: authHeader() });
  }

  getAllComputers() {
    return axios.get(API_URL, { headers: authHeader() });
  }

  getOneComputer(id) {
    return axios.get(API_URL + id, { headers: authHeader() });
  }

  updateComputer(id, computer) {
    return axios.put(API_URL + id, {
      brand: computer.brand,
      budget: computer.budget,
      comments: computer.comments,
      designation: computer.designation,
      encryption: computer.encryption,
      reference: computer.reference,
      origin: computer.origin,
      OS: computer.OS,
      owner: computer.owner,
      purchased: computer.purchased,
      room: computer.room,
      serial: computer.serial,
      type: computer.type,
      warranty: computer.warranty
    }, { headers: authHeader() });
  }

}

export default new ComputerService();
